@import './variables';
@import './carousel';
@import './fonts';

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

.fullColumn {
  max-width: pxToRem($desktop-xl);
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: 0 pxToRem(16);

  @include breakpoints-up($tablet-sm) {
    flex-wrap: nowrap;
    padding: 0 pxToRem(42);
  }

  @include breakpoints-up($desktop-sm) {
    padding: 0 pxToRem(72);
  }
}

.sectionContent {
  max-width: 41.0994671405em;
  margin: 0 auto;
}

h2 {
  font-size: pxToRem(21);
  line-height: pxToRem(35);
  margin: 0;

  @include breakpoints-up($tablet-sm) {
    font-size: pxToRem(34);
    line-height: pxToRem(54);
  }
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}