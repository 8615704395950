@import "~slick-carousel/slick/slick.css";

.slick-slider {
  min-width: 100%;
}

.slick-list {
  margin-right: pxToRem(-12);
  margin-left: pxToRem(-12);
}

.slick-slider {
  width: 100%;
}